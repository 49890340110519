import ExpiredTokenChannel from "@utils/expiredTokenChannel";

export const handleResponse = (response, suppress = false) => {
  if (response.status < 400) {
    return response
      .json()
      .then((d) => d)
      .catch((errorBody) => {
        throw errorBody;
      });
  }

  return response.json().then((errorBody) => {
    if (errorBody === "ActionController::InvalidAuthenticityToken") {
      const { onSendMessage } = new ExpiredTokenChannel();
      onSendMessage("logout");
      return;
    }
    throw errorBody;
  });
};
