import { getCookie } from "../../utils/cookie";

const getReduxHeaders = (getState) => {
  const token = getCookie("token");
  const { languageToTranslate } = getState().auth;

  const actualLang = languageToTranslate === "it" ? "lc" : languageToTranslate;
  const header = { lang: actualLang };
  const auth = token ? { auth: token } : {};

  return { ...header, ...auth };
};

export default getReduxHeaders;
