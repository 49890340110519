import { italyFlag } from "@components/images";
import { createSlice } from "@reduxjs/toolkit";
import { setCookie, checkCookie, eraseCookie, getCookie } from "@utils/cookie";

// Grab the logged-in user from localStorage (if there is one)
const userToken = getCookie("token");
const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
const lang = localStorage.getItem("language");
const flag = localStorage.getItem("flag");

// INIT Authenticate state object
export const initialState = {
  user,
  isLoggedIn: !!userToken,
  userData: JSON.parse(localStorage.getItem("userData")) || { userId: "" },
  languageToTranslate: lang || "it",
  flag: flag || italyFlag,
};

const { actions, reducer } = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    login: (state, action) => {
      const { token, userId, user } = action.payload;
      if (checkCookie("token")) {
        eraseCookie("token");
      } else {
        setCookie("token", token, 0.5);
      }

      localStorage.setItem("user", JSON.stringify(user));
      state.isLoggedIn = true;
      state.user = user;
      state.userData.userId = userId;
      localStorage.setItem("userData", JSON.stringify(state.userData));
    },
    logout: (state) => {
      eraseCookie("token");
      localStorage.removeItem("user");
      state.isLoggedIn = false;
      state.user = null;
      state.userData = { userId: "" };
    },
    userUpdate: (state, action) => {
      const getLocalStorageUser = JSON.parse(localStorage.getItem("user"));
      // eslint-disable-next-line camelcase
      const { confirm_email, ...restElements } = action.payload.updatedUser;
      const finalUser = {
        ...getLocalStorageUser,
        ...restElements,
      };
      state.user = finalUser;
      localStorage.setItem("user", JSON.stringify(finalUser));
    },
    setLanguage: (state, action) => {
      localStorage.setItem("language", action.payload);
      state.languageToTranslate = action.payload;
    },
    setFlagToTop: (state, action) => {
      localStorage.setItem("flag", action.payload);
      state.flag = action.payload;
    },
  },
});

export const { login, logout, setLanguage, setFlagToTop, userUpdate } = actions;

export default reducer;
